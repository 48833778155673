@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
* {
    font-family: 'Manrope';
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* max-width: 100%; */
    
    scrollbar-width: none;
}
/* *::-webkit-scrollbar {
    display: none;
} */

:-moz-selection {
    color: white;
    background: #51C87A;
}

 ::-webkit-selection {
    color: white;   
    background: #51C87A;
}

 ::selection {
    color: white;
    background: #51C87A;
}
::-webkit-scrollbar{
    width:10px;
}
::-webkit-scrollbar-track{
    background: transparent;
    
}
::-webkit-scrollbar-thumb{
    background-image: linear-gradient(#B4C7DB,#B4C7DB);
    border-radius: 25px;
    -webkit-border-radius: 10px;
}

a {
    color: #000;
}


/* header */

.header {
    background-color: #fff;
    box-shadow: 0 1px 9px 2px #33333369;
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 17000;
    /* box-shadow:0px 14px 10px -6px rgb(255 238 84 / 50%) #000; */
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
    
}
.tagLine{
    visibility: hidden;
    width: 0;
    height: 0;
}
.header li a {
    display: block;
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}

.selected {
    color: #217EDC;
}

.header .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    float: left;
    font-size: 2em;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    text-decoration: none;
    margin-left: 0;
}


/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
    margin-right: 140px;
}

@media (max-width: 69em) {
    .header .menu {
        text-align: center;
        width: 100%;
    }
}


/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 16px 10px;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}


/* menu btn */

.header .menu-btn {
    display: none;
}

.navOpen {
    max-height: 370px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 8px 20px 2px #33333352;

    border-radius: 0 0 20px 20px;
}

.header .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}


/* 48em = 768px */

@media (min-width: 1123px) {
    .closeIcon {
        visibility: hidden;
    }
    .tagLine{
        visibility: visible;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        font-size: small;
        margin-left: 2em;
        font-weight: 600;
        text-align: center;
    }
    .header {
        background-color: #fff;
        position: fixed;
        width: 100%;
        display: block;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        z-index: 17000;
    }
    .header li {
        float: left;
    }
    .header li a {
        padding: 15px 30px;
    }
   
    .header .menu {
        clear: none;
        float: right;
        max-height: none;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin-right: 0;

    }
    .header .menu-icon {
        display: none;
    }
    .header .logo {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        float: left;
        font-size: 2em;
        text-decoration: none;
        margin-left: 80px;
    }
    .header .logo img {
        width: 4.5em;
    }
}
.custom-shape-divider-bottom-1619165531 {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.custom-shape-divider-bottom-1619165531 svg {
    position: relative;
    display: block;
    width: calc(237% + 1.3px);
    height: 212px; 
}

.custom-shape-divider-bottom-1619165531 .shape-fill {
    fill: #2D89E7;
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-bottom-1619165531 svg {
        width: calc(245% + 1.3px);
        height: 164px;
    }
}

.landingPageContainer::-webkit-scrollbar {
    display: '1em';
}
.landingPageContainer{
    scrollbar-width: 1em;
}
.custom-shape-divider-bottom-1619168723 {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    
}

.custom-shape-divider-bottom-1619168723 svg {
    position: relative;
    display: block;
    width: calc(166% + 1.3px);
    height: 61px;
}

.custom-shape-divider-bottom-1619168723 .shape-fill {
    fill: hsl(0, 0%, 100%);
}

.card-container{
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    color:#fff;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background: #2D89E7; 
    padding-bottom: 9.7em;
}
.reviewContainer{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top:1em;
    cursor: pointer;
}

.masterPiece{
    margin: 5em 0em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.masterPiece .heading{
    font-weight:bolder;
    font-size: 1.5em;
    padding: 0.5em;
}
.masterPiece .desc{
    font-weight:200;
    font-size: 1em;
    width: 30em;
}
@media screen and (max-height: 700px) {
    .masterPiece{
        margin: 5em 0em 5em 0em;
      }
    
}
@media screen and (max-width: 480px) {
    .card-container{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px ;
        
        font-size: 0.8em;
    }
    .masterPiece{
        margin-top: 5em 0em 2em 0em;
        font-size: 0.7rem;
    }
    .masterPiece .heading{
        font-weight:bolder;
        font-size: 1.5em;
        padding: 0.5em;
    }
    .masterPiece .desc{
        font-weight:200;
        font-size: 1em;
        width: 30em;
    }
}



.MasterPieceCardsContainer{
    margin-top: 5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}












.subscribeIp{
  border-radius: 20em;
  padding: 0.8em;
  width: 18em;
  margin-top: 5px;
  border: transparent;
}
.commonFooterContainer{
  
  border-top-left-radius:-50%;
  border-top-right-radius:-50%;
}
.innerContent{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
@media (max-width:1280px){
.innerContent{
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
}
.site-footer
{
  background-color:#135FAC;
  padding:45px 0 0 0;
  font-size:15px;
  line-height:24px;
  color:#fff;
}
.site-footer hr
{
  border-top-color:#FFF;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#000;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  padding: 1em;
  display:inline
}
.NavFooterLink
{
  color: #000;
  text-decoration: none;
}
.NavFooterLink:focus
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:left
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#fff
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:left
  }
  .footer-links li{
    display: block;
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}

.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#fff;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background: #B4C7DB;
  color:#000;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}

.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.insta:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}

.footerLinkSelected{
  border-radius: 25px;
  background-color: #135FAC;
  color: #FFF;
  padding: 0.5em 1em;
}
.footerLinkSelected:hover{

  color: #FFF;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }



.loader {
    color: #2dc9f0;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  


.contactusform {
    padding: 3em;
    font-size: 1em;
}

@media screen and (max-width: 450px) {
    .contactusform {
        padding: 1em 0.2em;
        font-size: 1em;
    }
}

.contactusformContainer {
    background: #fff;
    border-radius: 20px;
    color: #000;
    -webkit-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.13);
    margin-bottom: 4em;
    box-shadow: 0px 28px 20px 20px rgb(255 255 255 / 10%);}

.contactusform input {
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 2.5em;
    bottom: 9.02%;
    padding: 0 10px 0 10px;
    width: 25em;
    background: #F3F3F3;
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
}

.contactusform textarea {
    max-width: 339px;
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 4.5em;
    padding: 0 10px 0 10px;
    bottom: 9.02%;
    width: 25em;
    background: #F3F3F3;
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
}

.contactusform button {
    font-size: 1em;
    top: 437px;
    margin-top: 10px;
    background: #FFEE54;
    border-radius: 20.5px;
    border:1px solid transparent;
    font-weight: 600;
    width: 8em;
    height: 3em;
    color: #121212;
}

.contactusform button:hover {
    box-shadow: 0px 14px 10px -8px rgb(255 238 84 / 40%);
    font-size: 1em;
    top: 437px;
    margin-top: 10px;
    background: #FFEE54;
    border-radius: 20.5px;
    width: 8em;
    height: 3em;
    color: #121212;
}
.card-style{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 33rem;
    padding: 0.8rem;
}
.right-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 2rem;
    color: #fff;
}
.cardImg{
    width: 20em;
    height: 7em;
}

@media screen and (max-width: 1100px) {
    .card-style{
        width: 30rem;
    }
}



@media screen and (max-width: 768px) {
    .cardImg{
        width: 18em;
    }
    
    .right-content{
        font-size: 0.9em;
     }
     .card-style{
        overflow:hidden;
    }
  }

@media screen and (max-width: 358px) {
    .cardImg{
        width: 13em;
        height: 6em;
    }
    
    .right-content{
        font-size: 0.5em;
     }
  }
.contentLeftContainer {
    font-size: 0.9em;
    padding: 0.5em;
}

.contentHeading {
    font-weight: bolder;
    margin-bottom: 1em;
    min-height: 3em;
    min-width: 10em;
    
}

.buttonGetaQuote {
    border: 2px solid transparent;
    border-radius: 2em;
   
    color: #000;
    width: 10em;
    height: 3em;
    font-weight: bold;
    padding: 0.3em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    background-color: #FFEE54;
}

.buttonGetaQuote:hover {
    box-shadow: 0px 14px 10px -6px rgb(255 238 84 / 40%);
    transition: all 0.2s ease-in-out;
    background: #FFEE54;
}

@media screen and (max-width: 768px) {
    .buttonGetaQuote {
        font-size: 1em;
        padding: 0.1em;
        width: 10em;
        height: 3em;
    }
    .contentHeading {
        font-size: 2.5em;
        min-height: 4em;
        padding-top: 2em;
    }
}

@media screen and (max-width: 448px) {
    .contentHeading {
        font-size: 2.3em;
        min-height: 5.2em;
        padding-top: 3em;
    }
}
@media screen and (max-width: 1280px) {
    .contentHeading {
        
        padding-top: 1em;
    }
}


.contentRightContainer{
    font-size: 0.7em;
    padding: 1em;
}
.contentRightContainer img{
    width: 16em;
    height: 16em;
}

@media screen and (max-width: 768px) {
    .contentRightContainer img{
    width: 100%;
    height: 100%;

    }
    .contentRightContainer{
        padding: 0em;
    }
  }
.contentBlockContainer{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 4% 2em 0 2em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: #fff;
  font-size: 3rem;
}

/* @media screen and (max-width: 1100px) {
.contentBlockContainer{
/* margin-top: 2.5em;    
}
}*/

@media screen and (max-width: 609px) {
  .contentBlockContainer{
    /* padding-top: 4.1%; */
  margin-top: 10%;    
  font-size: 0.9rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  }
}

@media screen and (max-width: 770px) {
  .contentBlockContainer{
    /* padding-top: 4.1%; */
  margin-top: 5%;    
  font-size: 0.9rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  }
}
@media screen and (max-width: 408px) {
  .contentBlockContainer{
    /* padding-top: 3.7%; */
  margin-top: 15%;    
  font-size: 0.7rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  }
} 
.WorkScroll {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: #fff;
    -webkit-justify-content: center;
            justify-content: center;
  }
.testimonials {
    background: #f1f1f1;
    color: #434343;
    text-align: center;
}

.inner {
    margin: auto;
    overflow: hidden;
    padding: 0 20px;
    background-color: #fff;
}

.border {
    width: 160px;
    height: 5px;
    background: #2C88E6;
    margin: 26px auto;
}

.row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.col {
    -webkit-flex: 33.33% 1;
            flex: 33.33% 1;
    max-width: 33.33%;
    box-sizing: border-box;
    padding: 15px;
}

.testimonial {
    border: 1px solid #000;
    background: #FFF;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 5px 5px #EFEFEF;
}

.testimonial:hover {
    background: #D8EBFF;
    color: #000;
}

.testimonial img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.name {
    font-size: 1em;
    text-transform: uppercase;
    margin: 20px 0;
    font-weight: bold;
}

.btnAllReview {
    padding: 0.2em;
    border: 1px solid #000;
    background-color: transparent;
    text-align: center;
    font-size: 1em;
    border-radius: 25px;
    width: 10em;
    cursor: pointer;
    height: 3em;
    margin-top: 1em;
}

@media screen and (max-width:960px) {
    .col {
        -webkit-flex: 100% 1;
                flex: 100% 1;
        max-width: 80%;
    }
}

@media screen and (max-width:600px) {
    .col {
        -webkit-flex: 100% 1;
                flex: 100% 1;
        max-width: 100%;
    }
}
.achievementItemContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}
    .div-container{
    margin-top:1em;
    padding: 0;

    }
    .tooltiptext{
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
      
        /* Position the tooltip */
        position: absolute;
        z-index: 14;
      }
      
      .locationCanadaDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationAmericaDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationIndiaDiv:hover .tooltiptext {
        visibility: 
        visible;
      }
      .locationItalyDiv:hover .tooltiptext {
        visibility: 
        visible;
      }
      .locationMaltaDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationSpainDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationPortugalDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationSpainDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationAustraliaDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationNewZealandDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationCyprusDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationDenmarkDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationGermanyDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationGreeceDiv:hover .tooltiptext {
        visibility: visible;
      }
      .locationIrelandDiv:hover .tooltiptext {
        visibility: visible;
      }
.worldImageContainer{
    position: relative;
}
.worldImageContainer .worldimg{
   display: block;
   width: 100%;
}
.worldImageContainer .locationImg1{
    position: absolute; bottom:40%; 
}

.locationCanada,.locationAmerica,.locationIreland,.locationDenmark,.locationGermany,.locationPortugal,.locationNewZealand,.locationSpain,.locationItaly,.locationCyprus,.locationGreece,.locationMalta,.locationIndia,.locationAustralia{
    width: 2em;
    cursor: pointer;
}
@media screen and (min-width: 449px) {

    .worldImageContainer{
    padding: 40px;
    }
    .worldImageContainer .locationAmericaDiv{
        position: absolute; 
        bottom:56.25%;
        left: 20.9%; 
        width: 2em;
    }
    .worldImageContainer .locationCanadaDiv{
        position: absolute; 
        bottom:66.2%;
        width: 2em;
        left: 17.85%; 
    }
   
    .worldImageContainer .locationIrelandDiv{
        position: absolute; 
        bottom:73.5%;
        width: 2em;
        left: 43.01%; 
    }
    .worldImageContainer .locationDenmarkDiv{
        position: absolute; 
        bottom:69.4%;
        left: 48.2%; 
        width: 2em;

    }

    .worldImageContainer .locationGermanyDiv{
        position: absolute; 
        bottom:68.4%;
        left: 47.2%; 
        width: 2em;
    }
    .worldImageContainer .locationPortugalDiv{
        position: absolute; 
        width: 2em;
        bottom: 59.9%;
        left: 44.5%;
    }

    .worldImageContainer .locationSpainDiv{     
    position: absolute; 
    width: 2em;
    bottom: 60.9%;
    left: 46.1%; 
    }
    .worldImageContainer .locationItalyDiv{     
    position: absolute; 
    width: 2em;
    bottom: 61%;
    left: 49.21%;
    }
    .worldImageContainer .locationGreeceDiv{     
    position: absolute; 
    width: 2em;
    bottom: 59.9%;
    left: 51.7%;
    }
    
    .worldImageContainer .locationMaltaDiv{     
    position: absolute; 
    width: 2em;
    bottom: 57.4%;
    left: 50.2%;
    }
    .worldImageContainer .locationCyprusDiv{     
    position: absolute; 
    width: 2em;
    bottom: 57.7%;
    left: 53.7%;
    }
    .worldImageContainer .locationIndiaDiv{     
    position: absolute; 
    width: 2em;
    bottom: 51.4%;
    left: 65.87%;
    }
   
    .worldImageContainer .locationAustraliaDiv{     
    position: absolute; 
    width: 2em;
    bottom: 20.9%;
    left: 79.25%;
    }
    .worldImageContainer .locationNewZealandDiv{     
    position: absolute; 
    width: 2em;
    bottom: 10.7%;
    left: 88.1%;
    }
}



    @media screen and (max-width: 450px) {
    .contactusimg,.worldimg{
    margin-top: 2em;
    width: 20.5em;
    }
    .worldImageContainer .locationAmerica{
        position: absolute; 
        bottom:46.25%;
        left: 17.8%; 
    }
    .worldImageContainer .locationCanada{
        position: absolute; 
        bottom:56.4%;
        left: 14.5%; 

    }
    .worldImageContainer .locationIreland{
        position: absolute; 
        bottom:63.5%;
        left: 41.9%; 
    }
    .worldImageContainer .locationDenmark{
        position: absolute; 
        bottom:59.4%;
        left: 47.5%; 

    }

    .worldImageContainer .locationGermany{
        position: absolute; 
        bottom:58.9%;
        left: 46.9%; 
    }
    .worldImageContainer .locationPortugal{
        position: absolute; 
        bottom: 49.9%;
        left: 43.3%;
    }

    .worldImageContainer .locationSpain{     
    position: absolute; 
    bottom: 50.9%;
    left: 45.2%; 
    }
    .worldImageContainer .locationItaly{     
    position: absolute; 
    bottom: 51%;
    left: 48.6%;
    }
    .worldImageContainer .locationGreece{     
    position: absolute; 
    bottom: 49.9%;
    left: 51.3%;
    }
    
    .worldImageContainer .locationMalta{     
    position: absolute; 
    bottom: 47.4%;
    left: 49.7%;
    }
    .worldImageContainer .locationCyprus{     
    position: absolute; 
    bottom: 48%;
    left: 53.3%;
    }
    .worldImageContainer .locationIndia{     
    position: absolute; 
    bottom: 41.6%;
    left: 66.59%;
    }
   
    .worldImageContainer .locationAustralia{     
    position: absolute; 
    bottom: 11.8%;
    left: 81.2%;
    }
    .worldImageContainer .locationNewZealand{     
    position: absolute; 
    bottom: 1.7%;
    left: 90.73%;
    }
    .locationCanada,.locationAmerica,.locationIreland,.locationDenmark,.locationGermany,.locationPortugal,.locationNewZealand,.locationSpain,.locationItaly,.locationCyprus,.locationGreece,.locationMalta,.locationIndia,.locationAustralia{
        width: 1em;
    }

    }

    .firstsection{
    position:relative;
    width: 100%;
    /* height: 100vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;

    }
    .firstsection:before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2776C6;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5); 
    position: absolute;
    width: 2067px;
    height: 1167px;
    left: calc(50% - 2067px/2 - 0.5px);
    bottom: 390px;
  
    }
    .div-container{
    margin-top:1em;
    padding: 0;

    }
    .customSection{
    position:relative;
    width: 100%;
    height: 80vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;

    }
    @media screen and (min-width: 450px)
    {
    .locationImg{
        width: 3em;
    }
    }
    @media screen and (max-width:600px) {
    /* .firstsection{
    height: 200vh;

    } */
    .content{
        position: relative;
        z-index: 1;
        margin: 0 auto;
        width: 400px;
        text-align: center;
    }
    }
    .customSection:before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2776C6;

    }


    .content{
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 1500px;
    text-align: center;
    }
    .content h2{
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 2em; 
    }
    .content p{
    color: #fff;
    font-size: 1.2em; 
    }
    .content span{
    color: #fff;
    }
    
    @media screen and (max-width: 813px) {
    .firstSection:before{
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #2776C6;
        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
        -webkit-transform: scaleX(2);
                transform: scaleX(2);
        width: 850px;
        height: 850px;
        left: calc(50% - 850px/2 + 11px);
        top: 3093px;
    }

    }
@media screen and (max-width : 450px){
.contactUsContainer h3{
    font-size: 2em;
}
}
.custom-shape-divider-top-1619178483 {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1619178483 svg {
    position: relative;
    display: block;
    width: calc(237% + 1.3px);
    height: 158px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.social-icon
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icon li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icon li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#fff;
  font-weight:700;
  font-size:13px
}
.social-icon a{
  color:#fff;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  transition:all .2s linear
}
.instagram{
    background: #f09433; 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
      
}
.custom-shape-divider-top-1619178483 .shape-fill {
    fill: #2B87E5;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-top-1619178483 svg {
        width: calc(158% + 1.3px);
        height: 81px;
    }
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-top-1619178483 svg {
        width: calc(245% + 1.3px);
        height: 49px;
    }
}

.aboutUsContainerHeading{
    z-index: '12';
    color:'#FFFF';
    height:'15%'; 
    margin-top: '4.6em';
     background: '#0087CD';
     display:'flex';
     -webkit-justify-content:'center';
             justify-content:'center';
     -webkit-align-items:'center';
             align-items:'center'; 
}
.aboutUsContainerHeadingBackground{
    position:'relative';
    z-index:'0';
    bottom:'1em';
    height:'0';
}
.aboutData{
    background-color: #F6f6f6;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 3em 0;
    position: relative;
        /* border-top-left-radius:10%;
    border-top-right-radius:10%; */
    /*border-bottom-left-radius:30%; 
    border-bottom-right-radius:30%;*/
}
@media screen and (max-width : 670px){
    .curveOurCoreValues{
        visibility: hidden;
        
    }
    }
.zoom-effect-container img {
    transition: 0.4s ease;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    cursor: pointer;
    -webkit-justify-content: center;
            justify-content: center;
}

.zoom-effect-container:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.custom-shape-divider-top-1619178483 {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1619178483 svg {
    position: relative;
    display: block;
    width: calc(237% + 1.3px);
    height: 158px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.custom-shape-divider-top-1619178483 .shape-fill {
    fill: #2B87E5;
}


/** For tablet devices **/

@media (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-top-1619178483 svg {
        width: calc(158% + 1.3px);
        height: 81px;
    }
}


/** For mobile devices **/

@media (max-width: 767px) {
    .custom-shape-divider-top-1619178483 svg {
        width: calc(245% + 1.3px);
        height: 49px;
    }
}

.custom-shape-divider-top-1619302615 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1619302615 svg {
    position: relative;
    display: block;
    width: calc(118% + 1.3px);
    height: 49px;
}

.custom-shape-divider-top-1619302615 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-bottom-1619302524 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.custom-shape-divider-bottom-1619302524 svg {
    position: relative;
    display: block;
    width: calc(118% + 1.3px);
    height: 49px;
}

.custom-shape-divider-bottom-1619302524 .shape-fill {
    fill: #FFFFFF;
}
.mainBlogContainer li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 5px 0;
    width: 10%;
}
.mainBlogContainer li button {
    border-radius: 15px;
    width: 100%;
    height: 10px;
    transition: background-color 1000ms linear;
}



.backgroundImg {
    text-align: center;
    overflow: hidden;
    background-image: url(/static/media/background.04bc570f.svg);
    height: 500px;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 485px) {
    .mainBlogContainer {
        margin-top: 22%;
    }
    .writeUpContainer {
        left: 50%;
        bottom: 3%;
        width: 80%;
    }
}


@media ( min-width: 486px)and (max-width: 760px) {
    .mainBlogContainer {
        margin-top: 15%;
    }
    .writeUpContainer {
        left: 45%;
        bottom: 5%;
        width: 80%;
    }
}

@media (min-width:761px) and (max-width: 1240px) {
    .mainBlogContainer {
        margin-top: 10%;
    }
    .writeUpContainer {
        left: 55%;
        bottom: 5%;
        width: 80%;
    }
}

@media (min-width: 1241px) {
    .mainBlogContainer {
        margin-top: 7%;
    }
    .writeUpContainer {
        left: 35%;
        bottom: 7%;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
}
.container ::-webkit-scrollbar {
    width: 1em;
}

.container ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
}

.container ::-webkit-scrollbar-track {
    background: transparent;
}

.container form {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 30px 0 40px 0;
}

.container input {
    display: none;
}

.container label {
    color: #000;
    font-weight: 700;
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 40px;
    transition: .3s;
    cursor: pointer;
}

.container input:checked~label {
    background: #0061BB;
    padding: 5px 10px;
    border-radius: 40px;
    box-shadow: 5px 2px 15px #a8b4fc80;
    color: #fff;
}

.container .DataView {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    overflow-x: scroll;
    padding: 0;
    width: 100%;
  }
  .container ul {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    overflow-x: scroll;
    padding: 0;
    width: 100%;
  }
  /* @media (max-width: 530px) {
    .container .DataView {
        display: flex;
        list-style: none;
        overflow-x: scroll;
        padding: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
} */


.container li {
    margin: 10px 10px;
}

.container figure {
    z-index: 1;
    min-width: 300px;
    overflow: hidden;
    margin: 0 5px;
    -webkit-animation: show .8s ease;
            animation: show .8s ease;
}

@-webkit-keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.container img {
    /* /* width: 300px; */
    height: 250px; 
    width: inherit;
    left: 0;
    object-fit: cover;
    background-color: #BABABA;
    border-radius: 20px;
    box-shadow: 0 10px 15px #a8b4fc30;
}

.container figcaption {
    font-size: 24px;
    margin-top: 20px;
}

.container figure figcaption {
    margin: 0;
    position: relative;
    z-index: -1;
}

.container figure:hover figcaption {
    opacity: 1;
    -webkit-transform: translateY(-19px);
            transform: translateY(-19px);
}

.container figcaption {
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
    background: white;
    border-radius: 0 0 20px 20px;
    padding: 30px 0 20px 0;
    transition: .3s;
}

.container span {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #969696;
}

.container #root a {
    color: #0061BB;
}

.container .photo-source {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #969696;
    margin-bottom: 40px;
}


@-webkit-keyframes transform {
    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}


@keyframes transform {
    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

@-webkit-keyframes movement {
    0%,
    100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: translateY(20%) rotateY(10deg);
                transform: translateY(20%) rotateY(10deg);
    }
}

@keyframes movement {
    0%,
    100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: translateY(20%) rotateY(10deg);
                transform: translateY(20%) rotateY(10deg);
    }
}
.bodyBlog img{
    width:100%;
    height:100%
}
@media (max-width: 960px) {
.sticky{
    visibility:hidden;
}
}
.container-height{
    padding-top: 9%;
}
@media (max-width: 500px) {
    .container-height{
        padding-top: 6em;
    }
}
@media (max-width: 400px) {
    .container-height{
        padding-top: 6em;
    }
}
.mainBlogContainer li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 5px 0;
    width: 10%;
}
.mainBlogContainer li button {
    border-radius: 15px;
    width: 100%;
    height: 10px;
    transition: background-color 1000ms linear;
}



.backgroundImg {
    text-align: center;
    overflow: hidden;
    background-image: url(/static/media/background.04bc570f.svg);
    height: 500px;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 485px) {
    .mainBlogContainer {
        margin-top: 22%;
    }
    .writeUpContainer {
        left: 50%;
        bottom: 3%;
        width: 80%;
    }
}


@media ( min-width: 486px)and (max-width: 760px) {
    .mainBlogContainer {
        margin-top: 15%;
    }
    .writeUpContainer {
        left: 45%;
        bottom: 5%;
        width: 80%;
    }
}

@media (min-width:761px) and (max-width: 1240px) {
    .mainBlogContainer {
        margin-top: 10%;
    }
    .writeUpContainer {
        left: 55%;
        bottom: 5%;
        width: 80%;
    }
}

@media (min-width: 1241px) {
    .mainBlogContainer {
        margin-top: 7%;
    }
    .writeUpContainer {
        left: 35%;
        bottom: 7%;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }



.loader {
    color: #2dc9f0;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  


.contactusform {
    padding: 3em;
    font-size: 1em;
}

@media screen and (max-width: 450px) {
    .contactusform {
        padding: 1em 0.2em;
        font-size: 1em;
    }
}

.contactusformContainer {
    background: #fff;
    border-radius: 20px;
    color: #000;
    
    -webkit-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.13);
    margin-bottom: 4em;
    box-shadow: 0px 28px 20px 20px rgb(255 255 255 / 10%);}

.contactusform input {
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 2.5em;
    bottom: 9.02%;
    padding: 0 10px 0 10px;
    width: 100%;
    background: #F3F3F3;
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
}

.contactusform input:disabled {
  left: 0%;
  right: 0%;
  top: 19.67%;
  height: 2.5em;
  bottom: 9.02%;
  padding: 0 10px 0 10px;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #C7C7C7;
  box-sizing: border-box;
  border-radius: 4px;
}
.contactusform input[type="file"] {
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 2.5em;
    bottom: 9.02%;
    padding: 10px 0 10px 0;
    width: 25em;
    background: #ffffff;
    border: 0.5px solid #ffffff;
    box-sizing:none ;
    border-radius: none;
    
}

.contactusform textarea {
    max-width: 339px;
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 4.5em;
    padding: 0 10px 0 10px;
    bottom: 9.02%;
    width: 25em;
    background: #F3F3F3;
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
}

.contactusform button {
    font-size: 1em;
    top: 437px;
    margin-top: 10px;
    background: #FFEE54;
    border-radius: 20.5px;
    border:1px solid transparent;
    font-weight: 600;
    width: 8em;
    height: 3em;
    color: #121212;
}

.contactusform button:hover {
    box-shadow: 0px 14px 10px -8px rgb(255 238 84 / 40%);
    font-size: 1em;
    top: 437px;
    margin-top: 10px;
    background: #FFEE54;
    border-radius: 20.5px;
    width: 8em;
    height: 3em;
    color: #121212;
}
