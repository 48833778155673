
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }



.loader {
    color: #2dc9f0;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  


.contactusform {
    padding: 3em;
    font-size: 1em;
}

@media screen and (max-width: 450px) {
    .contactusform {
        padding: 1em 0.2em;
        font-size: 1em;
    }
}

.contactusformContainer {
    background: #fff;
    border-radius: 20px;
    color: #000;
    
    -webkit-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.13);
    margin-bottom: 4em;
    box-shadow: 0px 28px 20px 20px rgb(255 255 255 / 10%);}

.contactusform input {
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 2.5em;
    bottom: 9.02%;
    padding: 0 10px 0 10px;
    width: 100%;
    background: #F3F3F3;
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
}

.contactusform input:disabled {
  left: 0%;
  right: 0%;
  top: 19.67%;
  height: 2.5em;
  bottom: 9.02%;
  padding: 0 10px 0 10px;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #C7C7C7;
  box-sizing: border-box;
  border-radius: 4px;
}
.contactusform input[type="file"] {
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 2.5em;
    bottom: 9.02%;
    padding: 10px 0 10px 0;
    width: 25em;
    background: #ffffff;
    border: 0.5px solid #ffffff;
    box-sizing:none ;
    border-radius: none;
    
}

.contactusform textarea {
    max-width: 339px;
    left: 0%;
    right: 0%;
    top: 19.67%;
    height: 4.5em;
    padding: 0 10px 0 10px;
    bottom: 9.02%;
    width: 25em;
    background: #F3F3F3;
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
}

.contactusform button {
    font-size: 1em;
    top: 437px;
    margin-top: 10px;
    background: #FFEE54;
    border-radius: 20.5px;
    border:1px solid transparent;
    font-weight: 600;
    width: 8em;
    height: 3em;
    color: #121212;
}

.contactusform button:hover {
    box-shadow: 0px 14px 10px -8px rgb(255 238 84 / 40%);
    font-size: 1em;
    top: 437px;
    margin-top: 10px;
    background: #FFEE54;
    border-radius: 20.5px;
    width: 8em;
    height: 3em;
    color: #121212;
}