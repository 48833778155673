@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');
* {
    font-family: 'Manrope';
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* max-width: 100%; */
    
    scrollbar-width: none;
}
/* *::-webkit-scrollbar {
    display: none;
} */

:-moz-selection {
    color: white;
    background: #51C87A;
}

 ::-webkit-selection {
    color: white;   
    background: #51C87A;
}

 ::selection {
    color: white;
    background: #51C87A;
}
::-webkit-scrollbar{
    width:10px;
}
::-webkit-scrollbar-track{
    background: transparent;
    
}
::-webkit-scrollbar-thumb{
    background-image: linear-gradient(#B4C7DB,#B4C7DB);
    border-radius: 25px;
    -webkit-border-radius: 10px;
}
